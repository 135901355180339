.buttonLarge {
    @apply uppercase tracking-wider w-max flex max-h-8 items-center justify-center rounded-md border border-transparent py-2 px-4 text-sm font-serif text-white shadow-sm duration-150 ease-in-out hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 gap-2;
}
.buttonSmall {
    @apply uppercase w-max flex items-center justify-center rounded-md border border-transparent py-1 px-3 text-xs font-serif text-white shadow-sm duration-150 ease-in-out hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 gap-2;
}

.buttonRound {
    @apply bg-neutral-100 text-neutral-400 flex justify-center items-center aspect-[1/1] h-6 w-6 hover:bg-primary-light/10 transition-all p-4 cursor-pointer rounded-full overflow-hidden hover:text-primary;
}
