.form-input {

    @apply relative h-8 m-0 block focus:z-20 w-full appearance-none rounded border border-solid border-gray-300 bg-white bg-clip-padding bg-no-repeat py-1 px-3 text-xs md:text-sm font-normal text-gray-700 transition ease-in-out focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:bg-orange-100/30 focus:ring-primary focus:shadow;

    .withLabel {
        @apply rounded-tl-none rounded-bl-none z-20 relative;
    }


}

.form-textarea {
    @apply relative h-max m-0 block focus:z-20 w-full appearance-none rounded border border-solid border-gray-300 bg-white bg-clip-padding bg-no-repeat py-1 px-3 text-sm font-normal text-gray-700 transition ease-in-out focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:bg-orange-100/30 focus:ring-primary focus:shadow;
}


.form-input-label {
    @apply left-1 relative z-10 inline-flex h-8 items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm ;
}

.form-input-label-top {
    @apply text-sm text-gray-500 tracking-wide flex w-full;
}
