.queryInfo {

    span {

        strong {

            @apply text-primary;

        }

    }

}
