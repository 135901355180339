@font-face {
    font-family:  'Roboto';
    font-weight:  400;
    font-display: swap;
    src:          url('/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}


@font-face {
    font-family:  'Roboto';
    font-weight:  600;
    font-display: swap;
    src:          url('/assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}


@font-face {
    font-family:  'Roboto';
    font-weight:  700;
    font-display: swap;
    src:          url('/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

