.react-daterange-picker__inputGroup {

    text-align:      center;
    display:         inline-flex;
    align-items:     center;
    justify-content: center;
    gap: 5px;


    .react-daterange-picker__inputGroup__input {
        font-size:  12px;
        padding:    0px 0px 0px 0px;
        //min-width: 20px;
        background: transparent;
        text-align: center;
    }

    .react-date-picker__inputGroup__divider {
        font-size: 12px;
        padding:   0px 0px 0px 0px;
    }

    .react-daterange-picker__inputGroup__day {
        //margin-right: 2px;
    }

    .react-daterange-picker__inputGroup__day {
        padding: 0px 4px 0px 4px;
    }


    .react-daterange-picker__inputGroup__leadingZero {
        display: none !important;
    }
}

.react-daterange-picker__clear-button {
    display: none;
}
