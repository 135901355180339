.MuiTableBody-root {

    tr {
        transition: all;
        cursor:     pointer;

        &:hover {
            background: #ffeadb !important;
        }


    }

}
