@tailwind base;
@tailwind components;
@tailwind utilities;

//@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Slab&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import "forms";
@import "font_RobotoSlab";
@import "font_RobotoMono";
@import "font_Roboto";
@import "buttons";
@import "datatables";
@import "reactQuery";
@import "typo";
@import "queryInfo";
@import "install";
@import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
@import 'react-calendar/dist/Calendar.css';
@import "calendarDateRange";
@import "reactDataGridTables";

@layer components {
}

@layer utilities {

    * {
        text-rendering:              optimizeLegibility;
        -webkit-font-smoothing:      antialiased;
        -moz-osx-font-smoothing:     grayscale;
        -webkit-tap-highlight-color: transparent;

    }


    body {
        @apply bg-neutral-200 overflow-x-hidden;
    }

    .flip {
        transform: scaleX(-1);
    }

    .gradientSuccess {
        @apply bg-gradient-to-b from-success to-success-dark;
    }

    .gradientPrimary {
        @apply bg-gradient-to-b from-primary to-primary-dark;
    }
}

.Toastify {
    z-index: 999999;

    > div {
        z-index: 99999;
    }
}

.MuiTableHead-root {
    position: relative;
    z-index:  10;
}

.MuiTableCell-head {
    z-index: 10 !important;
}

.errorText {
    @apply text-red-700 text-sm tracking-wide;
}

section {
    border: 0;
}

.teamPersonTitle {
        color: red !important;
    a {
        @apply text-primary;
    }
}

