
.Install {

    position:        relative;
    display:         flex;
    justify-content: flex-start;
    flex-direction:  column;

    p {
        @apply text-neutral-700;
    }

    h2 {
        @apply text-primary font-serif text-2xl pb-4 pt-2;
    }

    .step {
        position:        relative;
        display:         flex;
        justify-content: flex-start;
        flex-direction:  column;

        margin-bottom:   20px;
        padding-bottom:  20px;
        border-bottom:   1px dotted #999999;

    }

    img {
        max-width:  400px;
        border:     1px solid #EEEEEE;
        box-shadow: 0 0 5px #999999;
        @apply my-4;
    }

    .MuiAlert-root {
        max-width: 400px;
        margin-bottom: 10px;
    }

}
